import { yupResolver } from "@hookform/resolvers/yup";
import { ToggleType } from "components/toggleButtonGroup";
import { attendanceErrors, dayFormat } from "consts";
import { format, sub } from "date-fns";
import {
  ModalParamsType,
  SearchMailingInDSModel,
  SearchMailingModel,
  SearchOmnigModel,
  SubChannelTypeEnum,
} from "models";
import {
  useAttendance,
  useChannel,
  useList,
  useProfile,
  useToast,
  useUser,
} from "providers";
import { useCallback, useMemo, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as AttendanceService from "services/attendance";
import { Errors } from "services/errors";
import {
  AddAttendanceSchema,
  addTimeSuffix,
  applyMask,
  aytyFormatError,
  removeMask,
} from "utils";

export enum StepAttendanceEnum {
  searchAttendance,
  searchContact,
  searchContactInDS,
  addContact,
}

type AddAttendanceFormType = {
  step: StepAttendanceEnum;
  name: string;
  ddd: string;
  phone: string;
  email: string;
  cpfCnpj: string;
  channel: number;
  folder: number;
  user: number;
  start: string;
  end: string;
};

type FieldsType = Omit<AddAttendanceFormType, "name" | "ddd" | "email">;

const endDate = new Date();
const startDate = sub(endDate, { days: 1 });

const initialFields: FieldsType = {
  step: StepAttendanceEnum.searchAttendance,
  phone: "",
  cpfCnpj: "",
  channel: 0,
  folder: 0,
  user: 0,
  start: format(startDate, dayFormat),
  end: format(endDate, dayFormat),
};

const { Preview, WhatsApp } = SubChannelTypeEnum;

export default function AddAttendanceViewModel({ onClose }: ModalParamsType) {
  const [loading, setLoading] = useState(false);
  const [loadingItem, setLoadingItem] = useState<number>();
  const [fields, setFields] = useState({ ...initialFields });
  const [attendances, setAttendances] = useState<SearchOmnigModel[]>();
  const [contactsInDS, setContactsInDS] = useState<SearchMailingInDSModel[]>();
  const [contacts, setContacts] = useState<SearchMailingModel[]>();
  const {
    reset,
    handleSubmit,
    setValue,
    clearErrors,
    register,
    formState: { errors },
  } = useForm<AddAttendanceFormType>({
    resolver: yupResolver(AddAttendanceSchema),
  });
  const { pIdProject, user } = useProfile();
  const { folderOpts } = useList();
  const { activeUsers } = useUser();
  const { activeChannels } = useChannel();
  const { getAttendances } = useAttendance();
  const { t } = useTranslation("modals");
  const { error, warning, success } = useToast();
  const navigate = useNavigate();

  const errorsResolver = useMemo(
    () => new Errors({ error, warning }, t),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const hasSearchDS = useMemo(() => user?.hasDialerQuery, [user]);

  const allowedChannels = useMemo(
    () =>
      activeChannels?.filter((c) =>
        [WhatsApp, Preview].includes(c.IdSubChannelType)
      ),
    [activeChannels]
  );

  const isSearchAttendance = useMemo(
    () => fields.step === StepAttendanceEnum.searchAttendance,
    [fields]
  );

  const isSearchContact = useMemo(
    () => fields.step === StepAttendanceEnum.searchContact,
    [fields]
  );

  const isSearchContactInDS = useMemo(
    () => fields.step === StepAttendanceEnum.searchContactInDS,
    [fields]
  );

  const isAddContact = useMemo(
    () => fields.step === StepAttendanceEnum.addContact,
    [fields]
  );

  const emptyTable = useMemo(
    () =>
      (isSearchAttendance && !attendances?.length) ||
      (isSearchContact && !contacts?.length) ||
      (isSearchContactInDS && !contactsInDS?.length) ||
      isAddContact,
    [
      isSearchAttendance,
      attendances,
      isSearchContact,
      isSearchContactInDS,
      contacts,
      contactsInDS,
      isAddContact,
    ]
  );

  const handleInit = useCallback(async () => {
    setValue("step", fields.step);
    setValue("start", fields.start);
    setValue("end", fields.end);
  }, [fields, setValue]);

  const handleChangeField = useCallback(
    (key: keyof FieldsType, value: any) => {
      let newValue = value;

      if (key === "phone") {
        newValue = applyMask(
          newValue,
          removeMask(newValue).length > 7 ? "phone" : "number"
        );
      }

      if (key === "cpfCnpj") {
        newValue = applyMask(
          newValue,
          removeMask(newValue).length > 11 ? "cnpj" : "cpf"
        );
      }

      setFields((current) => ({ ...current, [key]: newValue }));
      setValue(key, newValue);
    },
    [setValue]
  );

  const handleChangeStep = useCallback(
    (step: ToggleType) => {
      handleChangeField("step", step);
      clearErrors();
    },
    [handleChangeField, clearErrors]
  );

  const handleReset = useCallback(() => {
    reset();
    setFields((current) => ({ ...initialFields, step: current.step }));
    setAttendances(undefined);
    setContacts(undefined);
    setContactsInDS(undefined);
  }, [reset]);

  const handleClose = useCallback(
    (id?: number) => {
      handleReset();
      if (id) navigate(`./${id}`);
      onClose();
    },
    [handleReset, onClose, navigate]
  );

  const validateAddResult = useCallback(
    (idReturnAPI: number, idOmni: number) => {
      switch (true) {
        case idReturnAPI > 0:
          success({ description: t("alerts.successfullyAdded") });
          getAttendances(true);
          handleClose(idOmni);
          break;
        case idReturnAPI === attendanceErrors.alreadyInAttendance:
          warning({ description: t("errors.isAlreadyInAttendance") });
          break;
        case idReturnAPI === attendanceErrors.noVoiceAnswering:
          warning({ description: t("errors.noVoiceAnswering") });
          break;
        case idReturnAPI === attendanceErrors.invalidQueue:
          warning({ description: t("errors.invalidQueue") });
          break;
        case idReturnAPI === attendanceErrors.unassociatedUser:
          warning({ description: t("errors.unassociatedUser") });
          break;
        case idReturnAPI === attendanceErrors.voiceClientExists:
          warning({ description: t("errors.voiceClientExists") });
          break;
        default:
          warning({ description: t("errors.cloudNotStart") });
          break;
      }
    },
    [success, warning, t, getAttendances, handleClose]
  );

  const handleAdd = useCallback(
    async (id: number, alternativeId?: number, idOmniSource?: number) => {
      setLoadingItem(alternativeId ?? id);
      if (pIdProject)
        await AttendanceService.addAttendance({
          pIdProject,
          pIdMailing: id,
          pIdOmniSource: idOmniSource ?? fields.channel,
        })
          .then(({ data }) => validateAddResult(data.idReturnAPI, data.idOmni))
          .catch(errorsResolver.defaultError);
      setLoadingItem(undefined);
    },
    [pIdProject, fields, errorsResolver, validateAddResult]
  );

  const handleAddByDS = useCallback(
    async (id: number, ddd: string, phone: string, nameMailing: string) => {
      setLoadingItem(id);
      if (pIdProject)
        await AttendanceService.addAttendanceByDS({
          pIdProject,
          pIdMailingDialerAPI: id,
          pIdOmniSource: fields.channel,
          pNuDDD: ddd,
          pNuPhone: phone,
          pNmMailing: nameMailing,
        })
          .then(({ data }) => validateAddResult(data.idReturnAPI, data.idOmni))
          .catch(errorsResolver.defaultError);
      setLoadingItem(undefined);
    },
    [pIdProject, fields, errorsResolver, validateAddResult]
  );

  const submit: SubmitHandler<AddAttendanceFormType> = useCallback(
    async (data) => {
      setLoading(true);
      const { channel } = data;
      if (pIdProject)
        switch (data.step) {
          case StepAttendanceEnum.addContact:
            await AttendanceService.createContact({
              pIdProject,
              pNmMailing: data.name,
              pNuDdd: data.ddd,
              pNuPhone: removeMask(data.phone),
              pIdOmniSource: channel,
              pNuRegistration: removeMask(fields.cpfCnpj),
            })
              .then(async ({ data }) => {
                if (data.idReturnAPI > 0)
                  await handleAdd(data.idMailing, channel);
                else warning({ description: t(aytyFormatError(data)) });
              })
              .catch(errorsResolver.defaultError);
            break;
          case StepAttendanceEnum.searchContact:
            await AttendanceService.searchContact({
              pIdProject,
              pNmMailing: data.name,
              pNuDdd: data.ddd,
              pNuPhone: removeMask(data.phone),
              pIdOmniFolder: fields.folder,
              pNuRegistration: removeMask(fields.cpfCnpj),
            })
              .then(({ data }) => {
                if (data.idReturnAPI > 0) setContacts(data.mailingList);
                else warning({ description: t(aytyFormatError(data)) });
              })
              .catch(errorsResolver.defaultError);
            break;
          case StepAttendanceEnum.searchContactInDS:
            await AttendanceService.searchContactInDS({
              pIdProject,
              pNmMailing: data.name,
              pNuDdd: data.ddd,
              pNuPhone: removeMask(data.phone),
              pIdOmniFolder: fields.folder,
              pNuRegistration: removeMask(fields.cpfCnpj),
            })
              .then(({ data }) => {
                if (data.idReturnAPI > 0) setContactsInDS(data.mailingList);
                else warning({ description: t(aytyFormatError(data)) });
              })
              .catch(errorsResolver.defaultError);
            break;
          case StepAttendanceEnum.searchAttendance:
            await AttendanceService.searchAttendance({
              pIdProject,
              pNmMailing: data.name,
              pNuDdd: data.ddd,
              pNuPhone: removeMask(data.phone),
              pIdOmniFolder: fields.folder,
              pNuRegistration: removeMask(fields.cpfCnpj),
              pIdUser: fields.user,
              pDtStart: addTimeSuffix(fields.start),
              pDtFinish: addTimeSuffix(fields.end, true),
            })
              .then(({ data }) => {
                if (data.idReturnAPI > 0) setAttendances(data.omniList);
                else warning({ description: t(aytyFormatError(data)) });
              })
              .catch(errorsResolver.defaultError);
            break;
          default:
            break;
        }
      setLoading(false);
    },
    [pIdProject, fields, errorsResolver, warning, t, handleAdd]
  );

  return {
    loading,
    loadingItem,
    hasSearchDS,
    activeUsers,
    folderOpts,
    allowedChannels,
    fields,
    attendances,
    contacts,
    contactsInDS,
    errors,
    emptyTable,
    isSearchAttendance,
    isSearchContact,
    isSearchContactInDS,
    isAddContact,
    t,
    register,
    handleInit,
    handleChangeStep,
    handleChangeField,
    handleReset,
    handleClose,
    handleAdd,
    handleAddByDS,
    onSubmit: handleSubmit(submit),
  };
}

export type VMReturnType = Pick<
  ReturnType<typeof AddAttendanceViewModel>,
  | "loadingItem"
  | "attendances"
  | "contacts"
  | "contactsInDS"
  | "t"
  | "handleAdd"
  | "handleAddByDS"
>;
