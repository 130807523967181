import {
  GaussianBlurBackgroundProcessor,
  VirtualBackgroundProcessor,
} from "@twilio/video-processors";
import { customerEnded, customerIsOut, ls, videoBgKey } from "consts";
import { useMap } from "hooks";
import { BaseProviderType, PermissionProviderType } from "models";
import { useToast } from "providers/toast";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import * as AttendanceService from "services/attendance";
import {
  LocalTrackPublication,
  LocalVideoTrack,
  RemoteParticipant,
  RemoteTrackPublication,
  RemoteVideoTrack,
  Room,
  VideoProcessor,
  connect,
} from "twilio-video";
import { aytyFormatError } from "utils";
import { VideoCallErrors } from "./errors";

export enum VideoBgEnum {
  none = "none",
  blur = "blur",
  bg1 = "bg1",
  bg2 = "bg2",
  bg3 = "bg3",
  bg4 = "bg4",
}

type VideoCallContextType = {
  loading: boolean;
  localList?: LocalVideoTrack[];
  remoteList?: RemoteVideoTrack[];
  agentName?: string;
  customerPath?: string;
  hasRoom: boolean;
  hasSomeoneRemote: boolean;
  audioEnabled: boolean;
  validateHasVideo: (omni: string) => boolean;
  onConnect: (
    token: string,
    roomName: string,
    agent?: string
  ) => Promise<boolean>;
  initVideoCall: (pIdOmni: string) => Promise<boolean>;
  onDisconnect: (pIdOmni: string) => void;
  onToggleAudio: () => void;
  onEditVideoBg: (bg: VideoBgEnum) => void;
};

const VideoCallContext = createContext({} as VideoCallContextType);

const initialVideoBg = ls.getItem(videoBgKey) as VideoBgEnum | null;

let blurProcessor: GaussianBlurBackgroundProcessor;
let imgProcessor: VirtualBackgroundProcessor;

const videoDisconnectStatus = "disconnected";

const Provider = ({ children }: BaseProviderType) => {
  const [loading, setLoading] = useState(false);
  const [idOmni, setIdOmni] = useState<string>();
  const [room, setRoom] = useState<Room>();
  const [agentName, setAgentName] = useState<string>();
  const [local, localActions] = useMap<string, LocalVideoTrack>();
  const [remote, remoteActions] = useMap<string, RemoteVideoTrack>();
  const [customerPath, setCustomerPath] = useState<string>();
  const [audioEnabled, setAudioEnabled] = useState(true);
  const [videoBg, setVideoBg] = useState(initialVideoBg);
  const { t } = useTranslation();
  const { error, warning, info } = useToast();

  const errorsResolver = useMemo(
    () => new VideoCallErrors({ error, warning }, t),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const isRoomHost = useMemo(() => !!idOmni, [idOmni]);

  const hasSomeoneRemote = useMemo(() => !!remote?.size, [remote]);

  const localList = useMemo(() => {
    if (local) return Array.from(local.values());
  }, [local]);

  const remoteList = useMemo(() => {
    if (remote) return Array.from(remote.values());
  }, [remote]);

  const hasRoom = useMemo(() => !!room, [room]);

  const validateHasVideo = useCallback(
    (omni: string) => omni === idOmni,
    [idOmni]
  );

  const onDisconnect = useCallback(
    (pIdOmni?: string) => {
      if (idOmni === pIdOmni || idOmni === undefined) {
        room?.disconnect();

        setRoom(undefined);
        setAgentName(undefined);
        setCustomerPath(undefined);
        setIdOmni(undefined);
      }
    },
    [idOmni, room]
  );

  const localParticipantConnected = useCallback(
    (publication: LocalTrackPublication) => {
      if (publication.kind === "video") {
        const track = publication.track as LocalVideoTrack;
        localActions.add(track.id, track);
      }
    },
    [localActions]
  );

  const addRemotePublication = useCallback(
    (publication: RemoteTrackPublication) => {
      if (publication.kind === "video" && publication.track) {
        const track = publication.track as RemoteVideoTrack;
        remoteActions.add(track.sid, track);
      }
    },
    [remoteActions]
  );

  const participantConnected = useCallback(
    (participant: RemoteParticipant) => {
      participant.on("trackPublished", (publication) =>
        addRemotePublication(publication)
      );

      participant.tracks.forEach((publication) => {
        publication.on("subscribed", (track: RemoteVideoTrack) => {
          remoteActions.add(track.sid, track);
        });

        addRemotePublication(publication);
      });
    },
    [remoteActions, addRemotePublication]
  );

  const participantDisconnected = useCallback(
    (participant: RemoteParticipant) => {
      participant.tracks.forEach((_, key) => remoteActions.remove(key));

      if (!isRoomHost) onDisconnect();
      else if (room?.state !== videoDisconnectStatus)
        info({ description: t("alerts.endedVideoCall") });
    },
    [room, isRoomHost, remoteActions, onDisconnect, info, t]
  );

  useEffect(() => {
    const resetProcessor = (
      track: LocalVideoTrack,
      processor?: VideoProcessor
    ) => {
      if (track.processor) track.removeProcessor(track.processor);
      if (processor) track.addProcessor(processor);
    };

    switch (videoBg) {
      case VideoBgEnum.blur:
        room?.localParticipant.videoTracks.forEach(async ({ track }) => {
          if (!blurProcessor)
            blurProcessor = new GaussianBlurBackgroundProcessor({
              assetsPath: "/",
              maskBlurRadius: 10,
              blurFilterRadius: 10,
            });

          await blurProcessor.loadModel();
          resetProcessor(track, blurProcessor);
        });
        break;
      case VideoBgEnum.bg1:
      case VideoBgEnum.bg2:
      case VideoBgEnum.bg3:
      case VideoBgEnum.bg4:
        room?.localParticipant.videoTracks.forEach(async ({ track }) => {
          let img = new Image();
          img.src = `/assets/${videoBg}.jpg`;
          img.onload = async () => {
            if (!imgProcessor) {
              imgProcessor = new VirtualBackgroundProcessor({
                assetsPath: "/",
                backgroundImage: img,
                maskBlurRadius: 5,
              });
              await imgProcessor.loadModel();
            }

            imgProcessor.backgroundImage = img;
            resetProcessor(track, imgProcessor);
          };
        });
        break;
      default:
        room?.localParticipant.videoTracks.forEach(async ({ track }) =>
          resetProcessor(track)
        );
        break;
    }
  }, [room, videoBg]);

  useEffect(() => {
    room?.localParticipant.tracks.forEach(localParticipantConnected);
    room?.participants.forEach(participantConnected);

    return () => {
      room?.disconnect();
    };
  }, [room, localParticipantConnected, participantConnected]);

  useEffect(() => {
    room?.on("participantConnected", participantConnected);
    room?.on("participantDisconnected", participantDisconnected);
    room?.once("disconnected", () =>
      room.participants.forEach(participantDisconnected)
    );
  }, [room, participantConnected, participantDisconnected]);

  const onConnect = useCallback(
    async (token: string, roomName: string, agent?: string) => {
      setLoading(true);
      let result = false;

      await connect(token, { name: roomName })
        .then((room) => {
          setRoom(room);
          setAgentName(agent);
          result = true;
        })
        .catch(errorsResolver.connection)
        .finally(() => setLoading(false));

      return result;
    },
    [errorsResolver]
  );

  const sendPath = useCallback(
    async (pIdOmni: string, path: string) => {
      await AttendanceService.sendMessage({
        pIdOmni,
        pDeNewOmniMessage: path,
      })
        .then(({ data }) => {
          if (data.idReturnAPI > 0) return;

          if (data.idReturnAPI === customerIsOut)
            warning({ description: t("errors.customerIsOut") });
          else if (data.idReturnAPI === customerEnded)
            warning({ description: t("errors.customerEnded") });
          else if (data.idReturnAPI <= 0)
            warning({ description: t("errors.unsentMessage") });

          setCustomerPath(path);
        })
        .catch(errorsResolver.defaultError);
    },
    [errorsResolver, warning, t]
  );

  const initVideoCall = useCallback(
    async (pIdOmni: string) => {
      setLoading(true);
      const origin = window.location.origin;
      let result = false;

      await AttendanceService.createRoom()
        .then(async ({ data }) => {
          if (data.idReturnAPI > 0)
            await onConnect(data.token1, data.roomUniqueName).then(
              async (isSuccess: boolean) => {
                if (isSuccess) {
                  setIdOmni(pIdOmni);
                  const path = `${origin}/video-call?r=${data.roomUniqueName}`;
                  await sendPath(pIdOmni, path);
                  result = true;
                }
              }
            );
          else warning({ description: t(aytyFormatError(data)) });
        })
        .catch(errorsResolver.defaultError)
        .finally(() => setLoading(false));

      return result;
    },
    [errorsResolver, onConnect, sendPath, warning, t]
  );

  const onToggleAudio = useCallback(() => {
    room?.localParticipant.audioTracks.forEach(({ track }) => {
      audioEnabled ? track.disable() : track.enable();
      setAudioEnabled(!audioEnabled);
    });
  }, [audioEnabled, room]);

  const onEditVideoBg = useCallback((bg: VideoBgEnum) => {
    setVideoBg(bg);
    ls.setItem(videoBgKey, bg);
  }, []);

  return (
    <VideoCallContext.Provider
      value={{
        loading,
        localList,
        remoteList,
        agentName,
        customerPath,
        hasRoom,
        hasSomeoneRemote,
        audioEnabled,
        validateHasVideo,
        onConnect,
        initVideoCall,
        onDisconnect,
        onToggleAudio,
        onEditVideoBg,
      }}
    >
      {children}
    </VideoCallContext.Provider>
  );
};

export const VideoCallProvider = ({
  hasPermission = true,
  ...args
}: PermissionProviderType) => {
  if (!hasPermission) return <>{args.children}</>;

  return <Provider {...args} />;
};

export const useVideoCall = () => useContext(VideoCallContext);
