// Models

import { BaseReponseType } from "models";

export class OrderExportClass {
  IdOrder: number;
  NmOrderState: string;
  IdMailing: number;
  NmMailing: string;
  DtRegister: string;
  NmUserRegister: string;
  DtLastRegister: string;
  NmUserLastRegister: string;
  IdProduct: number;
  NmProduct: string;
  DeObs: string;
  IdOmni: number;

  constructor(o: OrderModel) {
    this.IdOrder = o.IdOrder;
    this.NmOrderState = o.NmOrderState;
    this.IdMailing = o.IdMailing;
    this.NmMailing = o.NmMailing;
    this.DtRegister = o.DtRegister;
    this.NmUserRegister = o.NmUserRegister;
    this.DtLastRegister = o.DtLastRegister;
    this.NmUserLastRegister = o.NmUserLastRegister;
    this.IdProduct = o.IdProduct;
    this.NmProduct = o.NmProduct;
    this.DeObs = o.DeObs;
    this.IdOmni = o.IdOmni;
  }
}

export type OrderModel = {
  IdOrder: number;
  IdOrdeState: number;
  NmOrderState: string;
  IdLastOrderEvent: number;
  IdMailing: number;
  NmMailing: string;
  DtRegister: string;
  IdUserRegister: number;
  NmUserRegister: string;
  DtLastRegister: string;
  IdUserLastRegister: number;
  NmUserLastRegister: string;
  IdProduct: number;
  NmProduct: string;
  DeObs: string;
  IsEditing: boolean;
  IdOmni: number;
  IdCall?: number;
  IdLastCall?: number;
  BindListData?: {
    NmControl: string;
    VlProperty: string;
    NmColumn: string;
  }[];
};

// Requests

export type CancelOrderRequest = {
  pIdOrder: number;
};

export type GetOrdersRequest = {
  pIdProject: number;
  pDtStart: string;
  pDtFinish: string;
  pIdOrderState?: number;
  pIdUser?: number;
  pIdUserAudit?: number;
};

// Responses

export type CancelOrderResponse = BaseReponseType & {
  idOrder: number;
};

export type GetOrdersResponse = BaseReponseType & {
  orderList: OrderModel[];
};
